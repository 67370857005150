import Link from 'next/link';
import classes from './Cta.module.css';
import { useIntl } from 'react-intl';

function Cta() {
  const intl = useIntl();

  const heading = intl.formatMessage({ id: "work-head"})
  const work_content = intl.formatMessage({ id: "work-p"})
  const work_btn = intl.formatMessage({ id: "work-btn"})

  return (
    <section className={classes.CtaSection}>
      <div className={classes.container}>
        <div className={classes.row}>
          <h4>{heading}</h4>
          <p>{work_content}</p>
          <div>
            <Link href="/contactus" passHref={true}>
              <button type="button" className={classes.contactus_button}>{work_btn}</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cta;