import About from "./About";
import Hero from "./Hero";
import Mauris from "./Mauris";
import Plantojoin from "./Plantojoin";
// import Success from "./Success";
import Highlights from "./Highlights";
import Highlights2 from "./Highlights2";
// import LatestBlog from "./LatestBlog";
// import Faq from "./Faq";
import Cta from "./Cta";
// import AppLink from "./AppLink";


function Home() {
  return (
    <div>
        <Hero />
        <About />
        {/* <Success /> */}
        <Mauris />
        <Plantojoin />
        <Highlights />
        <Highlights2 />
        {/* <LatestBlog/> */}
        {/* <Faq /> */}
        <Cta />
        {/* <AppLink /> */}
    </div>
  )
}

export default Home