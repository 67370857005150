import Image from 'next/image'
import { useRouter } from 'next/router';
import classes from './About.module.css'
import { FormattedMessage, useIntl } from 'react-intl';

function About() {
    const { locale } = useRouter();
    const intl = useIntl();

    const about = intl.formatMessage({ id: "about.about"})
    const sketshare = intl.formatMessage({ id: "about.sketshare"})
    return (
        <section className={classes.aboutSection}>
            <div className={classes.container}>
                <div 
                    className={classes.row} 
                    style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row', textAlign: locale === 'ar' ? 'end' : 'start'}}
                >
                    <div className={classes.col_md6}>
                        <h1>{about}<br /> <span className={classes.redText}>{sketshare}</span></h1>
                    </div>
                    <div className={classes.col_md6}>
                        <p 
                            className={classes.about_text}
                            style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                        >
                            <FormattedMessage 
                                defaultMessage={"With Sketshare, you can join thousands of like-minded individuals in exploring new ideas and challenges head-on with one big canvas. Whether it&apos;s a brainstorming session with your team or an online meeting to plan the next big project — we&apos;ve got your back."}
                                id='about.defination'
                            />
                        </p>
                    </div>
                </div>
                <div 
                    className={classes.row}
                    style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                >
                    <div className={classes.col4_md6}>
                        <div className={classes.card}>
                            <Image src="/images/brain.svg" className="card-img-top" height="85" width="100" alt="brain" />
                            <div className={classes.card_body}>
                                <h5 className={classes.cardHeadText}><FormattedMessage id='about-sub-heding-1' /></h5>
                                <p><FormattedMessage id='about-sub.def-1' /></p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col4_md6}>
                        <div className={classes.card}>
                            <Image src="/images/collab.svg" className="card-img-top" height="85" width="100" alt="collab" />
                            <div className={classes.card_body}>
                                <h5 className={classes.cardHeadText}><FormattedMessage id='about-sub-heding-2' /></h5>
                                <p><FormattedMessage id='about-sub.def-2' /></p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col4_md6}>
                        <div className={classes.card}>
                            <Image src="/images/communicate.svg" className="card-img-top" height="85" width="100" alt="comm" />
                            <div className={classes.card_body}>
                                <h5 className={classes.cardHeadText}><FormattedMessage id='about-sub-heding-3' /></h5>
                                <p><FormattedMessage id='about-sub.def-1' /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About