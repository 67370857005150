// import Link from 'next/link'
import classes from './Highlights.module.css'
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

function Highlights() {
    const { locale } = useRouter();
    const intl = useIntl();

    const highlighted = intl.formatMessage({ id: "features-highlighted"})
    const features = intl.formatMessage({ id: "features-features"})
    const hf_content = intl.formatMessage({ id: "features-P"})
    const sub_head = intl.formatMessage({ id: "feature-sub-H"})
    const sub_head_1 = intl.formatMessage({ id: "feature-sub-H-1"})
    const sub_head_2 = intl.formatMessage({ id: "feature-sub-H-2"})
    const sub_head_3 = intl.formatMessage({ id: "feature-sub-H-3"})
    const sub_p = intl.formatMessage({ id: "feature-sub-p"})
    const sub_p_1 = intl.formatMessage({ id: "feature-sub-p-1"})
    const sub_p_2 = intl.formatMessage({ id: "feature-sub-p-2"})
    const sub_p_3 = intl.formatMessage({ id: "feature-sub-p-3"})

    return (
        <section className={classes.highlightsSection}>
            <div className={classes.container}>
                <div 
                    className={classes.row} 
                    style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row'}}
                >
                    <div className={classes.col12_md6}>
                        <div className={classes.heading} style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                            <h2>{highlighted}<br /><span className={classes.app_blueText}>{features}</span></h2>
                        </div>
                    </div>
                    <div className={classes.col12_md6}>
                        <p className={classes.headtext} style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                            {hf_content}
                        </p>
                    </div>
                </div>
            </div>
            <div className={classes.container_fluid}>
                <div className={classes.row} style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                    <div className={classes.col12_md6_lg3}>
                        <div className={classes.cardheight}>
                            <div className={classes.card}>
                                <div className={classes.highImg}>
                                    <div className={classes.card_inner}>
                                        <div className={classes.textheight}>
                                            <h5><strong>{sub_head} </strong></h5>
                                            <p>{sub_p}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col12_md6_lg3}>
                        <div className={classes.cardheight}>
                            <div className={classes.card}>
                                <div className={classes.highImg}>
                                    <div className={classes.card_inner}>
                                        <div className={classes.textheight}>
                                            <h5><strong>{sub_head_1} </strong></h5>
                                            <p>{sub_p_1}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col12_md6_lg3}>
                        <div className={classes.cardheight}>
                            <div className={classes.card}>
                                <div className={classes.highImg}>
                                    <div className={classes.card_inner}>
                                        <div className={classes.textheight}>
                                            <h5><strong>{sub_head_2} </strong></h5>
                                            <p>{sub_p_2}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col12_md6_lg3}>
                        <div className={classes.cardheight}>
                            <div className={classes.card}>
                                <div className={classes.highImg}>
                                    <div className={classes.card_inner}>
                                        <div className={classes.textheight}>
                                            <h5><strong>{sub_head_3}</strong></h5>
                                            <p>{sub_p_3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Highlights;