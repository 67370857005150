import Image from 'next/image'
import { useRouter } from 'next/router';
import classes from './Hero.module.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FormattedMessage, useIntl } from 'react-intl';

function Hero(props: any) {

    const router = useRouter();
    const { locale } = useRouter();
    const intl = useIntl();

    const defination = intl.formatMessage({ id: "hero.defination" })
    const trustedby = intl.formatMessage({ id: "hero.trustedby" })

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        tablet1: {
            breakpoint: { max: 1024, min: 572 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 572, min: 0 },
            items: 1,
        },
    };

    return (
        <section className={classes.heroSection}>
            <div className={classes.lightgrayBg}>
                <div className={classes.container}>
                    <div
                        className={classes.row}
                        // style={{ flexDirection: locale === 'ar' ? 'row-reverse' : 'row' }}
                    >
                        <div className={classes.col}>
                            <div className={classes.heroContent}
                                // style={{ textAlign: locale === 'ar' ? 'end' : 'start' }}
                            >
                                {locale === 'ar' ? (
                                    <h1 className={classes.h1_ar}> 
                                        <span>                                       
                                            <FormattedMessage id='hero.arabic_def_1' />
                                        </span> 
                                        {" "}                                       
                                        <span className={classes.redBg_ar}>
                                            <FormattedMessage id='hero.arabic_def_2' />
                                        </span>
                                        {" "}
                                        <span className={classes.redBg_ar}>
                                            <FormattedMessage id='hero.arabic_def_3' />
                                        </span>
                                        {" "}
                                        <span>
                                            <FormattedMessage id='hero.arabic_def_4' />
                                        </span>
                                    </h1>
                                ) : (
                                    <h1>
                                        <span className={classes.redBg}>
                                            <FormattedMessage id='hero.work' />
                                        </span>,
                                        <span className={classes.yellowText}>
                                            <FormattedMessage id='hero.think' />,
                                        </span> <FormattedMessage id='hero.and' />
                                        <br />
                                        <FormattedMessage id='hero.succeedtogether' />
                                    </h1>
                                )}
                                <br />
                                {locale === 'ar' ? (
                                    <></>
                                ) : (
                                    <p style={{ textAlign: locale === 'ar' ? 'end' : 'start' }}>{defination}</p>
                                )}
                                <button
                                    className={classes.createBtn}
                                    onClick={() => router.push('/canvas')}
                                >
                                    <FormattedMessage id='hero.buttontext' />
                                </button>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes['heroImg']}>
                                <Image src='/hero_img.png' alt='...' width={'969px'} height={'652px'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.sliderArea}>
                <div className={classes.container}>
                    <h4 style={{ textAlign: locale === 'ar' ? 'end' : 'start' }}>{trustedby}</h4>
                    <div className={classes.carousel_content}>
                        <Carousel
                            autoPlaySpeed={3000}
                            autoPlay={props.deviceType !== "mobile" || "tablet" ? true : false}
                            responsive={responsive}
                            ssr={true}
                            arrows={false}
                            infinite={true}
                            itemClass="carousel-item-padding-40-px"
                        >
                            <div className={classes.partner} >
                                <Image src="/images/partners/ADGM.png" alt='partner1' width="250px" height="200" />
                            </div>
                            <div className={classes.partner} >
                                <Image src="/images/partners/sandbox.png" alt='partner2' width="400px" height="225px" />
                            </div>
                            <div className={classes.partner} >
                                <Image src="/images/partners/auc.png" alt='partner3' width="400px" height="300px" />
                            </div>
                            <div className={classes.partner_resize} >
                                <Image src="/images/partners/Dtec.png" alt='partner4' width="380px" height="134px" className={classes.image} />
                            </div>
                            <div className={classes.partner_resize} >
                                <Image src="/images/partners/newchip.png" alt='partner5' width="290px" height="100px" className={classes.image} />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;