import classes from './Highlights2.module.css';
import Image from "next/image";
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

function Highlights2() {
    const { locale } = useRouter();
    const intl = useIntl();

    const sketsharefor = intl.formatMessage({ id: "forAll.sketsharefor"})
    const all = intl.formatMessage({ id: "forAll.all"})
    const expl = intl.formatMessage({ id: "forAll.Expl"})

    return (
        <section className={classes.highlights2}>
            <div className={classes.container}>
                <div className={classes.row} style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row'}}>
                    <div className={classes.col12_md6}>
                        <div>
                            <Image src='/images/imagePlaceholder.png' alt='placeholder' width={'500px'} height={'500px'} />
                        </div>
                    </div>
                    <div className={classes.col12_md6} style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                        <h2>{sketsharefor} <br /><span className={classes.yellowColor}>{all}</span></h2>
                        <p 
                            className={classes.forall_text} 
                            style={{
                                width: locale === 'ar' ? '100%' : '',
                                textAlign: locale === 'ar' ? 'end' : 'start'
                            }}
                        >
                            {expl}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Highlights2;