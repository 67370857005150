import Link from 'next/link'
import classes from './Plantojoin.module.css'
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

function Plantojoin() {
    const { locale } = useRouter();
    const intl = useIntl();

    const areyouready = intl.formatMessage({ id: "join.areyoureadyto"})
    const joinusnow = intl.formatMessage({ id: "join.joinusnow"})
    const joinus_def = intl.formatMessage({ id: "join-Expl"})
    const get_started_btn = intl.formatMessage({ id: "join.btn"})

    return (
        <section className={classes.plantojoin}>
            <div className={classes.container}>
                <div 
                    className={classes.row}
                    style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row'}}
                >
                    <div className={classes.colmd12_lg6}>
                        <div 
                            className={classes.plantojoinContent} 
                            style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                        >
                            <div>
                                <h2 
                                    className={classes.headtext}
                                    style={{
                                        textAlign: locale === 'ar' ? 'end' : 'start',
                                        width: locale === 'ar' ? '97%' : ''
                                    }}
                                >
                                    {areyouready} <span className={classes.app_redText}>{joinusnow}</span>
                                </h2>
                            </div>
                            <div className={classes.content}>
                                <p style={{textAlign: locale === 'ar' ? 'end' : 'start'}}>
                                    {joinus_def}
                                </p>
                            </div>
                            <Link href="/canvas" passHref={true}>
                                <button className={classes.getStartedBtn}>{get_started_btn}</button>
                            </Link>
                        </div>
                    </div>
                    <div className={classes.colmd12_lg6}>
                        <div className={classes.videoSection}>
                            <div className={classes.ratio}>
                                <iframe
                                    src="https://www.youtube.com/embed/OWC2Fc-6qkk"
                                    title="YouTube video"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Plantojoin