import React from "react";
import Layout from "../components/layout";
import HomePage from "../components/HomePage";

const Home = () => {

  return (
    <Layout>
      <HomePage />
    </Layout>
  );
};

export default Home;
