import classes from './Mauris.module.css';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

function Mauris() {
    const { locale } = useRouter();
    const intl = useIntl();

    const defination = intl.formatMessage({ id: "ourgrowth.defination"})
    const our = intl.formatMessage({ id: "ourgrowth.our"})
    const growth = intl.formatMessage({ id: "ourgrowth.growth"})
    const countries = intl.formatMessage({ id: "ourgrowth.countries"})
    const downloads = intl.formatMessage({ id: "ourgrowth.downloads"})
    const activeusers = intl.formatMessage({ id: "ourgrowth.activeusers"})
    const countries_content = intl.formatMessage({ id: "ourgrowth.countries-content"})
    const downloads_content = intl.formatMessage({ id: "ourgrowth.downloads-content"})
    const activeusers_content = intl.formatMessage({ id: "ourgrowth.activeusers-content"})

    return (
        <section className={classes.maurisSection}>
            <div className={classes.container}>
                <div 
                    className={classes.row}
                    style={{flexDirection: locale === 'ar' ? 'row-reverse' : 'row'}}
                >
                    <div className={classes.col12_md6}>
                        <h2 
                            style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                        >
                            {our}
                            <br /> 
                            <span className={classes.app_blueText}>{growth}</span>
                        </h2>
                        <p 
                            style={{textAlign: locale === 'ar' ? 'end' : 'justify', width: locale === 'ar' ? '100%' : ''}} 
                            className={classes.ourgrowth_text}
                        >
                            {defination}
                        </p>
                        {/* <button className={classes.discoverBtn}>Discover</button> */}
                    </div>
                    <div className={classes.col12_md6}>
                        <div className={classes.maurisCountersDiv}>
                            <div className={classes.row}>
                                <div className={classes.col5_sm4}>
                                    <h2 className={classes.yellowText}>175 +</h2>
                                    <p>{countries}</p>
                                </div>
                                <div className={classes.col7_sm8}>
                                    <p 
                                        style={{textAlign: locale === 'ar' ? 'end' : 'start'}} 
                                        className={classes.maurisCounterText}
                                    >
                                        {countries_content}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.col5_sm4}>
                                    <h2 className={classes.yellowText}>1M +</h2>
                                    <p>{downloads}</p>
                                </div>
                                <div className={classes.col7_sm8}>
                                    <p 
                                        className={classes.maurisCounterText}
                                        style={{textAlign: locale === 'ar' ? 'end' : 'start'}}
                                    >
                                        {downloads_content}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.col5_sm4}>
                                    <h2 className={classes.yellowText}>180k +</h2>
                                    <p>{activeusers}</p>
                                </div>
                                <div className={classes.col7_sm8}>
                                    <p 
                                        style={{textAlign: locale === 'ar' ? 'end' : 'start'}} 
                                        className={classes.maurisCounterText}
                                    >
                                        {activeusers_content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Mauris;